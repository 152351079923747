// extracted by mini-css-extract-plugin
export var start = "contact-module--start--bgSSJ";
export var contact = "contact-module--contact--0eYOy";
export var location = "contact-module--location--tH1yL";
export var flexrow = "contact-module--flexrow--CV7iV";
export var flexcolumn = "contact-module--flexcolumn--O-2Yf";
export var infoday = "contact-module--infoday--z01bB";
export var call = "contact-module--call--2vHBn";
export var map = "contact-module--map--RAQc4";
export var info = "contact-module--info--V7G1g";
export var hours = "contact-module--hours--9+ZtX";
export var details = "contact-module--details--LJawm";
export var phone = "contact-module--phone--0hsYJ";